import React from 'react'
import Scrollspy from 'react-scrollspy'
import Scroll from './Scroll'
import { Link } from "gatsby"

const Nav = (props) => (
    <nav id="nav" className={props.sticky ? 'alt' : ''}>
        <Scrollspy items={ ['main', 'java', 'r', 'php', 'cplusplus', 'nodejs', 'go'] } currentClassName="is-active" offset={-300}>
            <li>
                 <Link to="/">Home</Link>
            </li>
            <li>
                    <Link to="/java">Java</Link>
            </li>
            <li>
                    <Link to="/r">R</Link>
            </li>
            <li>
                    <Link to="/cplusplus">C++</Link>
            </li>
            <li>
                    <Link to="/nodejs"> Node.js</Link>
            </li>
                        <li>
                                <Link to="/php">PHP</Link>
                        </li>
            <li>
                    <Link to="/go">GO</Link>
            </li>
        </Scrollspy>
    </nav>
)

export default Nav
